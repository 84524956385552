/* eslint-disable no-undef */
<template >
  <div class="step">
    <v-container pa="0" ma="0" style="max-width: 550px">
      <h1 class="styloText">
        <span v-html="label"></span>
      </h1>
      <p>
        <span v-html="description"></span>
      </p>
      <v-text-field
        :rules="rules"
        v-model="searchPlace"
        v-on:change="searchaPlace"
        placeholder="entre le nom d'un lieu, de ton entreprise ou une adresse"
      ></v-text-field>

      <ul class="placeList">
        <li v-for="place in places" :key="place.place_id">
          <input
            type="radio"
            :value="place.place_id"
            :id="place.place_id"
            v-on:change="setPlace()"
            :checked="place.distance <= 15 && places.length === 1"
            :disabled="place.distance > 15"
            v-model="selected"
          />
          <label :for="place.place_id">
            <span class="placeName">{{ place.name }}</span>
            <br />
            {{ place.formatted_address }}
            <span :class="place.distance > 15 ? 'action dist' : 'success dist'"
              >{{ place.distance }}Km</span
            >
          </label>
          <!--   -->
        </li>
      </ul>
      <br />
      <div id="map"></div>
      <br />
      <v-btn color="primary" x-large v-on:click="submit" :disabled="!value">{{
        button
      }}</v-btn>
    </v-container>
  </div>
</template>
<script>
import { store } from "@/store/index"; //Added Destructuring

// eslint-disable-next-line no-unused-vars
import { Client, Status } from "@googlemaps/google-maps-services-js";

import credentials from "@/credentials/ENV/gg_credentials.json";
// eslint-disable-next-line no-unused-vars
const client = new Client({});

export default {
  name: "placeComponent",
  props: {
    label: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    button: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      rules: [(value) => !!value || "Required."],
      searchPlace: "",
      places: [],
      selected: null,
      value: null,
      store: store,
      map: null,
      markers: [],
      location: { lat: 50.6311167, lng: 3.0121411 },
    };
  },
  mounted() {
    this.initMap();

    var script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${credentials.gg_api}&callback=initMap`;
    script.defer = true;
    script.async = true;
    var _self = this;
    // eslint-disable-next-line no-unused-vars
    window.initMap = function (e) {
      // JS API is loaded and available
      _self.initMap();
    };

    // Append the 'script' element to 'head'
    document.head.appendChild(script);
  },
  methods: {
    searchaPlace: async function () {
      this.$emit("blured", this.selected);
      let places = await this.store.state.API.get(
        `/search/places?textSearch=${this.searchPlace}`
      );
      this.places = places.data.data;
      for (let marker of this.markers) {
        marker.setMap(null);
      }
      this.markers = [];
      for (let place of this.places) {
        place.distance = (
          Math.round(this.getDistance(place.geometry.location, this.location)) /
          1000
        ).toFixed(1);
        this.addMarker(place);
      }

      /* if (places.length === 1 && places[0].distance <= 15) {
        this.selected = places[0].place_id;
      } */
    },
    setPlace: async function () {
      let placeDetails = await this.store.state.API.get(
        `/search/places/${this.selected}`
      );
      /* this.value = this.places.filter(
        place => place.place_id == this.selected
      )[0]; */
      this.value = placeDetails.data.data;
    },
    addMarker(info) {
      this.markers.push(
        // eslint-disable-next-line no-undef
        new google.maps.Marker({
          position: info.geometry.location,
          map: this.map,
          title: "Hello World!",
        })
      );
    },
    initMap: function () {
      // var self = this;
      setTimeout(
        function () {
          // eslint-disable-next-line no-undef
          if (google) {
            // eslint-disable-next-line no-undef
            this.map = new google.maps.Map(document.getElementById("map"), {
              center: this.location,
              zoom: 10,
            });
            // eslint-disable-next-line no-unused-vars
            // eslint-disable-next-line no-undef
            new google.maps.Circle({
              strokeColor: "#5761ff",
              strokeOpacity: 0,
              strokeWeight: 0,
              fillColor: "#5761ff",
              fillOpacity: 0.15,
              map: this.map,
              center: this.location,
              radius: 15000,
            });
          }
        }.bind(this),
        500
      );
    },
    getDistance: function (from, to) {
      const R = 6371e3; // metres
      const φ1 = (from.lat * Math.PI) / 180; // φ, λ in radians
      const φ2 = (to.lat * Math.PI) / 180;
      const Δφ = ((to.lat - from.lat) * Math.PI) / 180;
      const Δλ = ((to.lng - from.lng) * Math.PI) / 180;
      const a =
        Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
        Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      return R * c; // in metres
    },
  },
};
</script>
<style scoped>
#map {
  width: 100%;
  height: 200px;
}
.placeName {
  font-size: 20px;
  font-weight: bold;
  line-height: 20px;
}
.dist {
  color: white;
  border-radius: 4px;
  padding: 4px;
  float: right;
  line-height: 20px;
  position: absolute;
  top: 0;
  right: 0;
}
.placeList {
  margin: 0;
  padding: 0;
}
.placeList li {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: left;
  line-height: 30px;
  position: relative;
}
input[type="radio"] {
  width: 32px;
  height: 32px;
  float: left;
  margin-right: 10px;
  border: 0px white !important;
  position: relative;
}
input[type="radio"]:before {
  content: "";
  position: absolute;
  width: 32px;
  height: 32px;
  background: rgb(206, 206, 206);
  border-radius: 100%;
}
input[type="radio"]:checked:before {
  background: #5761ff;
}
input[type="radio"]:after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  background: white;
  border-radius: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  transform: scale(0);
  transition: transform 0.2s linear;
}
input[type="radio"]:checked:after {
  transform: scale(1);
}
</style>