<template >
  <div class="step">
    <v-container pa="0" ma="0">
      <h1 class="styloText">
        <span v-html="label"></span>
      </h1>
      <p>
        <span v-html="description"></span>
      </p>
      <v-row class="mb-6" no-gutters>
        <v-col class="radioList" xs="12" v-for="(choice, index) in choices" :key="index+1">
          <input
            type="checkbox"
            class="mx-2"
            :id="choice.label"
            :value="choice.label"
            v-model="checkedNames"
          />
          <label :for="choice.label">
            <img :src="require(`@/assets/images/contactFrom/SVG/${choice.icon}.svg`)" width="128" />
            <br />
            {{choice.label}}
          </label>
        </v-col>
      </v-row>
      <p>
        <span v-for="entry in checkedNames" :key="entry">{{entry}}&nbsp;</span>
      </p>
      <v-btn
        color="primary"
        x-large
        v-on:click="submit"
        :disabled="!checkedNames || checkedNames.length === 0"
      >{{button}}</v-btn>

      <!-- <input type="checkbox" id="jack" value="Jack" v-model="checkedNames" />
      <label for="jack">Jack</label>
      <input type="checkbox" id="john" value="John" v-model="checkedNames" />
      <label for="john">John</label>
      <input type="checkbox" id="mike" value="Mike" v-model="checkedNames" />
      <label for="mike">Mike</label>
      <br />
      <span>Checked names: {{ checkedNames }}</span>-->
    </v-container>
  </div>
</template>
<script>
export default {
  name: "checkComponent",
  props: {
    label: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    button: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    choices: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selected: 0,
      value: [],
      checkedNames: [],
    };
  },
  mounted: function () {},
  methods: {
    submit: function () {
      // TODO emit response to the parent component
      this.value = this.checkedNames;
      this.$emit("submit", this.value);
    },
  },
};
</script>

<style scoped lang="scss">
label {
  position: relative;
}
label img {
  transform: scale(1);
  cursor: pointer;
  transition: transform 0.2s ease-out;
}
input[type="checkbox"]:hover + label img {
  transform: scale(1.1);
}
input[type="checkbox"]:checked + label img {
  filter: invert(1) grayscale(1) hue-rotate(0deg) saturate(100) brightness(100);
  transform: scale(0.8);
}
input[type="checkbox"]:checked + label {
  color: #5761ff;
}
input[type="checkbox"] + label:before {
  z-index: 0;
  position: absolute;
  width: 128px;
  height: 128px;
  background-color: #5761ff;
  border-radius: 100%;
  transform: scale(0);
  transition: transform 0.2s linear;
  content: "";
}
input[type="checkbox"]:checked + label:before {
  transform: scale(1);
}

.colInput {
  position: relative;
  max-width: 200px;
  width: 50%;

  min-width: unset !important;
}
input[type="checkbox"] {
  cursor: pointer;
  opacity: 0;
  position: absolute;
  width: 128px;
  height: 128px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 9;
}

.sm-and-up {
  label img {
    width: 48px;
    height: 48px;
  }
}

@media only screen and (max-width: 450px) {
  .radioList {
    display: inherit;
  }
  label {
    display: flex;
    line-height: 38px;
    margin: 10px;
    width: 100%;
    max-width: 100%;
  }
  label img {
    width: 38px;
    height: 38px;
    margin-right: 15px;
  }
  .colInput {
    max-width: 100%;
    width: 100%;
  }
  input[type="checkbox"] + label:before {
    width: 38px;
    height: 38px;
    left: 10px;
    top: 0px;
    right: unset;
    margin: 0;
    content: "";
  }
  input[type="checkbox"] {
    width: 38px;
    height: 38px;
    left: 0;
    right: unset;
    margin: 0;
  }
  .formcol {
    display: block;
  }
  h1 {
    font-size: 24px !important;
  }
  p {
    font-size: 12px;
  }
}
</style>