<template >
  <div class="step">
    <v-container pa="0" ma="0" style="max-width:550px;">
      <h1 class="styloText">
        <span v-html="label"></span>
      </h1>
      <p>
        <span v-html="description"></span>
      </p>
      <v-spacer style="height:50px; display:block; width:100%;"></v-spacer>
      <v-slider v-model="value" label step="5" thumb-label="always" ticks></v-slider>
      <v-btn color="primary" x-large v-on:click="submit">{{button}}</v-btn>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "Slide",
  props: {
    label: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    button: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    params: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selected: 0,
      value: 0,
    };
  },
  mounted: function () {},
  methods: {
    submit: function () {
      // TODO emit response to the parent component
      this.$emit("clicked", this.selected);
    },
  },
};
</script>