<template >
  <div class="step">
    <v-container pa="0" ma="0" style="max-width:550px;">
      <h1 class="styloText">
        <span v-html="label"></span>
      </h1>
      <p>
        <span v-html="description"></span>
      </p>
      <v-form v-model="valid">
        <v-text-field
          v-model="codeName"
          :rules="codeNameRules"
          :counter="25"
          label="Nom du projet"
          required
          v-on:change="updateValue"
        ></v-text-field>
        <v-textarea
          name="describe"
          v-model="describe"
          label="Description du projet"
          :rules="describeRules"
          :counter="250"
          value
          v-on:change="updateValue"
          hint="Décris le projet dans les grandes lignes"
        ></v-textarea>
        <v-btn color="primary" x-large v-on:click="submit" :disabled="!valid">{{button}}</v-btn>
      </v-form>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "Contact",
  props: {
    label: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    button: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      valid: false,
      codeName: "",
      describe: "",
      codeNameRules: [
        (v) => !!v || "Entre ton Prénom",
        (v) =>
          v.length <= 25 || "Ton prénom ne peu pas faire plus de 25 characters",
      ],
      describeRules: [
        (v) =>
          !!v || "Décris ton besoin, met un lien si ton projet existe déjà.",
        (v) =>
          v.length <= 250 || "La description est restreinte à 250 characters",
      ],
    };
  },
  mounted: function () {},
  methods: {
    updateValue: function () {
      this.value = { label: this.codeName, description: this.describe };
      this.$emit("blured", this.selected);
    },
    submit: function () {
      // TODO emit response to the parent component
      this.$emit("blured", this.selected);
      this.$emit("clicked", this.selected);
    },
  },
};
</script>