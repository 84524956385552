<template>
  <div class="contactForm">
    <div class="disable" v-if="!isActive"></div>
    <div class="backButton" style="pointer-events:none">
      <v-container pa="0" ma="0" v-if="prev.length > 1" style="pointer-events:none">
        <v-btn color="primary" icon v-on:click="goBack" style="pointer-events:all">
          <v-icon x-large color="primary">{{ iconBack }}</v-icon>
        </v-btn>
      </v-container>
    </div>
    <div ref="stepper"></div>
    <Recap label="Notre rendez-vous" description="recap rendez-vous" :recap="recap" v-show="end"></Recap>
    <Parallax
      :speed=".8"
      parallaxType="Offset"
      :style="'position:absolute; top:'+((isMobile)?320:220)+'vh;'"
      :marged="false"
      :masked="false"
      :skewed="false"
      :rotate="true"
      :degree="-45"
      :minMax="true"
    >
      <div
        style="width:20vh; height:20vh; transform:rotate(45deg) translateX(-40vh);"
        class="postit"
      ></div>
    </Parallax>

    <Parallax
      :speed=".8"
      parallaxType="Offset"
      :style="'top:'+((isMobile)?500:400)+'vh; position:absolute;'"
      :marged="false"
      :masked="false"
      :skewed="false"
      :rotate="true"
      :degree="-45"
      :minMax="true"
    >
      <div
        style="width:20vh; height:70vh; transform:rotate(45deg) translateX(120vw);"
        class="postit"
      ></div>
    </Parallax>
  </div>
</template>

<script>
import Parallax from "@/components/UI/Parallax";

import contactCreateScheme from "@/assets/schemes/contactCreateSchemes";

import radio from "@/components/Contact/Radio.vue";
import check from "@/components/Contact/Check.vue";
import date from "@/components/Contact/Date.vue";
import slide from "@/components/Contact/Slide.vue";
import place from "@/components/Contact/Place.vue";
/* import dateStartEnd from "@/components/Contact/DateStartEnd.vue"; */
import contact from "@/components/Contact/Contact.vue";
import details from "@/components/Contact/Details.vue";
import Recap from "@/components/Contact/Recap.vue";
import submit from "@/components/Contact/Submit.vue";
import Vue from "vue";
import { gsap, Power1 } from "gsap";
import { ScrollToPlugin } from "gsap/all";
import { mdiArrowUpCircleOutline } from "@mdi/js";
gsap.registerPlugin(ScrollToPlugin);

export default {
  // eslint-disable-next-line vue/no-unused-components
  components: {
    Parallax,
    Recap,
    /* // eslint-disable-next-line vue/no-unused-components
    radio,
    // eslint-disable-next-line vue/no-unused-components
    check,
    // eslint-disable-next-line vue/no-unused-components
    date,
    // eslint-disable-next-line vue/no-unused-components
    slide,
    // eslint-disable-next-line vue/no-unused-components
    details,
    // eslint-disable-next-line vue/no-unused-components
    submit,
    // eslint-disable-next-line vue/no-unused-components
    contact,
    // eslint-disable-next-line vue/no-unused-components
    place,
    // eslint-disable-next-line vue/no-unused-components
    recap */
  },
  computed: {
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        case "lg":
          return false;
        case "xl":
          return false;
        default:
          return false;
      }
    },
    options() {
      return {
        duration: this.duration,
        offset: this.offset,
        easing: this.easing,
        delay: 600,
      };
    },
  },
  mounted: function () {
    this.addComponent(this.scheme.default);
    document.documentElement.style.overflow = "hidden";
  },
  destroyed: function () {
    document.documentElement.style.overflow = "scroll";
  },
  data() {
    return {
      isActive: true,
      radio: radio,
      check: check,
      place: place,
      date: date,
      recap: [],
      contact: contact,
      details: details,
      submit: submit,
      slide: slide,
      scheme: contactCreateScheme,
      prev: [],
      components: [],
      next: null,
      duration: 600,
      delay: 300,
      offset: 0,
      target: window.document.body.scrollHeight,
      iconBack: mdiArrowUpCircleOutline,
      end: false,
    };
  },
  watch: {},
  methods: {
    handleAnimation: function () {},
    updateScroll: function () {
      this.target =
        window.document.body.scrollHeight - (window.innerHeight - 64);
      // window.scrollTo(0, this.target);
      this.scrollTo(this.target);
    },
    scrollTo(num) {
      this.isActive = false;
      /* setTimeout(
        function() { */
      let scrollPos = { x: 0, y: this.$store.state.offsetTop },
        _self = this;
      // eslint-disable-next-line no-unused-vars
      let tn = gsap.to(scrollPos, this.duration / 1000, {
        y: num,
        delay: this.delay / 1000,
        ease: Power1.easeInOut,
        onUpdate: function () {
          window.scrollTo(scrollPos.x, scrollPos.y);
        },
        onComplete: function () {
          /* setTimeout(function() { */
          _self.isActive = true;
          _self.components[_self.components.length - 1].value = null;
          /*  }, 1000); */
        },
      });
      /* }.bind(this),
        100
      ); */
    },
    goBack: function () {
      if (!this.isActive) return false;
      this.isActive = false;
      if (!this.end && this.prev.length > 1) {
        this.scrollTo(this.target - window.innerHeight);
        this.prev.pop();
        setTimeout(
          function () {
            this.components[this.components.length - 1].$destroy();
            this.components[
              this.components.length - 1
            ].$el.parentNode.removeChild(
              this.components[this.components.length - 1].$el
            );
            this.updateScroll();
            this.components.pop();
            this.isActive = true;
          }.bind(this),
          this.duration + this.delay
        );
      } else {
        this.end = false;
        this.scrollTo(window.document.body.scrollHeight - window.innerHeight);
        this.isActive = true;
      }
    },
    addComponent: function (step) {
      step.data = step;
      // this.prev[this.prev.length - 1].recap = [];
      this.recap = [];
      for (let comp of this.prev) {
        this.recap.push({
          label: comp.label,
          component: comp.component,
          value:
            comp.choices && comp.value && comp.value != 0
              ? comp.choices[comp.value - 1].label
              : comp.value,
        });
      }
      this.prev.push(step);
      if (this.prev[this.prev.length - 1].component === "recap") {
        this.end = true;
        this.scrollTo(window.document.body.scrollHeight);
      } else {
        let ComponentClass = Vue.extend(
            this[this.prev[this.prev.length - 1].component]
          ),
          _self = this;

        let instance = new ComponentClass({
          propsData: this.prev[this.prev.length - 1],
          methods: {
            submit() {
              let value = this.value;
              _self.setNextStep(value);
            },
          },
        });

        instance.$mount(); // pass nothing
        this.components.push(instance);
        this.$refs.stepper.appendChild(instance.$el);
        this.updateScroll();
      }
    },
    setNextStep: function (e) {
      this.prev[this.prev.length - 1].value = e; // setup client value selection
      if (
        this.prev[this.prev.length - 1].next &&
        this.prev[this.prev.length - 1].next === "classicEnd"
      ) {
        this.addComponent(this.scheme.classicEnd);
      } else if (this.prev[this.prev.length - 1].component === "radio") {
        if (
          this.prev[this.prev.length - 1].choices[e - 1].next === "classicEnd"
        ) {
          this.addComponent(this.scheme.classicEnd);
        } else {
          this.addComponent(
            this.prev[this.prev.length - 1].choices[e - 1].next
          );
        }
      } else {
        this.addComponent(this.prev[this.prev.length - 1].next);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@media only screen and (max-width: 450px) {
  h1 {
    font-size: 20px !important;
  }
  .step {
    padding-top: 100px;
  }
}
</style>