<template >
  <div class="step">
    <v-container pa="0" ma="0" style="max-width:550px;">
      <h1 class="styloText">
        <span v-html="label"></span>
      </h1>
      <p>
        <span v-html="description"></span>
      </p>

      <v-dialog
        ref="dialogDate"
        v-model="modal"
        :return-value.sync="day"
        persistent
        width="320px"
        :dark="false"
        scrollable
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="day"
            label="Picker in dialog"
            :prepend-icon="dayIcon"
            readonly
            v-bind="attrs"
            v-on="on"
            v-on:change="setValue"
            required="true"
          ></v-text-field>
        </template>
        <v-date-picker
          :full-width="true"
          v-model="day"
          scrollable
          :rules="dayRules"
          locale="fr-fr"
          :min="min"
          :landscape="false"
          :dark="false"
          :prev-icon="prevIcon"
          :next-icon="nextIcon"
          v-on:change="setValue"
        >
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="modal = false">Cancel</v-btn>
          <v-btn text color="primary" @click="$refs.dialogDate.save(day); modal = false">OK</v-btn>
        </v-date-picker>
      </v-dialog>

      <v-dialog
        ref="dialogHour"
        v-model="hourmodal"
        :return-value.sync="hour"
        persistent
        width="320px"
        :dark="false"
        scrollable
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="hour"
            label="Picker in dialog"
            :prepend-icon="hourIcon"
            readonly
            v-bind="attrs"
            v-on="on"
            v-on:change="setValue"
            required="true"
          ></v-text-field>
        </template>
        <v-time-picker
          v-model="hour"
          min="9:30"
          max="20:00"
          :allowed-hours="allowedHours"
          :allowed-minutes="allowedMinutes"
          locale="fr-fr"
          format="24hr"
          width="250"
          :full-width="true"
          :landscape="false"
          scrollable
          :dark="false"
          :prev-icon="prevIcon"
          :next-icon="nextIcon"
          v-on:change="setValue"
        >
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="hourmodal = false">Cancel</v-btn>
          <v-btn text color="primary" @click="$refs.dialogHour.save(hour)">OK</v-btn>
        </v-time-picker>
      </v-dialog>
      <div>{{value}}</div>
      <v-btn color="primary" x-large v-on:click="submit" :disabled="!value">{{button}}</v-btn>
    </v-container>
  </div>
</template>
<script>
import {
  mdiCalendar,
  mdiClockOutline,
  mdiArrowLeftCircleOutline,
  mdiArrowRightCircleOutline,
} from "@mdi/js";
// eslint-disable-next-line no-unused-vars
import moment from "moment";
export default {
  name: "dateComponent",
  props: {
    label: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    button: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      date: new Date().toISOString().substr(0, 10),
      modal: false,
      modals: false,
      hourmodal: false,
      value: null,
      dayIcon: mdiCalendar,
      hourIcon: mdiClockOutline,
      day: new Date().toISOString().substr(0, 10),
      dayRules: [(v) => !!v || "need to pick a date"],
      min: new Date().toISOString().substr(0, 10),
      hour: "9:30",
      allowedHours: [9, 10, 11, 12, 14, 15, 16, 17, 18, 19],
      allowedMinutes: [0, 15, 30, 45],
      current: new Date().toISOString().substr(0, 10),
      availableDates: [],
      prevIcon: mdiArrowLeftCircleOutline,
      nextIcon: mdiArrowRightCircleOutline,
    };
  },
  mounted() {
    this.setValue();
  },
  methods: {
    setValue() {
      this.value = this.day + " " + this.hour;
    },
    allowedDates(a) {
      return this.availableDates.includes(a);
    },

    // eslint-disable-next-line no-unused-vars
    pickerUpdate: function (val) {
      let totalDay = moment(val, "YYYY-MM").daysInMonth();

      let availableDates = [];

      let monthNow = moment().format("M");
      let monthSelected = moment(val).format("M");
      let day;

      if (monthNow == monthSelected) day = moment().format("D");
      else day = 1;

      for (let i = day; i <= totalDay; i++) {
        let date = moment()
          .month(val.split("-")[1] - 1)
          .date(i)
          .format("YYYY-MM-DD");
        if (moment(date).day() !== 0) availableDates.push(date);
      }
      // this.availableDates = availableDates;
      this.allowedDates(availableDates);
    },
  },
};
</script>