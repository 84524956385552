<template >
  <div class="step">
    <!-- <p>{{JSON.stringify(recap)}}</p> -->
    <v-container
      pa="0"
      ma="0"
      align="center"
      justify="center"
      style="max-width:550px"
      v-if="!emailSent"
    >
      <h1 class="styloText">
        <span v-html="label"></span>
      </h1>
      <p>
        <span v-html="description"></span>
      </p>
      <v-row align="center" justify="center">
        <v-card class align="left">
          <div class="white--text align-end primary" height="200px">
            <v-card-title>{{dateFR}}</v-card-title>
          </div>
          <!-- <div class="date pa-3">{{dateFR}}</div> -->
          <v-card-title align="center" style="text-align:center;" v-if="details">{{details.label}}</v-card-title>
          <v-card-text
            v-if="details"
          >{{details.description}} {{contact.firstname}} {{contact.email}}</v-card-text>
          <!-- <v-sheet height="200">
            <v-calendar
              ref="calendar"
              :now="today"
              :value="today"
              :events="events"
              color="primary"
              type="day"
              :locale="locale"
            ></v-calendar>
          </v-sheet>-->
          <div v-if="place !== null" class="pa-4">
            {{place.name}}
            <br />
            {{place.formatted_address}}
            <br />
            {{place.formatted_phone_number}}
            <!-- <br />
            {{place.website}}
            <br />
            {{place.url}}-->
          </div>
          <div class="pa-2 tags">
            <span v-for="record in recap" :key="record.value+'_'+(Math.random()*10)">
              <v-chip small class="ma-2" v-if="record.component === 'radio'">{{record.value}}</v-chip>
              <v-chip small class="ma-2" v-if="record.component === 'check'">{{record.value}}</v-chip>
            </span>
          </div>
          <v-switch
            v-model="cgu"
            style="padding-left:20px; padding-right:20px; margin:0; height:auto;"
            label="J'accepte les conditions générales d'utilisation Bige"
          ></v-switch>
          <v-btn
            color="primary"
            class="ma-5"
            v-on:click="saveTheDate"
            :disabled="!cgu"
          >Ajouter à mon agenda</v-btn>
        </v-card>
      </v-row>
      <br />
      <p>
        En cliquant sur ajouter à mon agenda, vous recevrez un lien de confirmation et l'évènement sera automatiquement ajouté à mon agenda
        <br />En cas d'indisponibilité, la date ainsi que le lieu sont suceptibles d'être modifiés.
        <br />Pour en savoir plus, reportez-vous aux
        <a
          href="/cgu"
          target="_blank"
        >conditions d'utilisation de bige</a>
      </p>

      <!-- <p>{{JSON.stringify(recap)}}</p> -->
    </v-container>
    <v-container
      pa="0"
      ma="0"
      align="center"
      justify="center"
      style="max-width:550px"
      v-if="emailSent"
    >
      <v-img
        :src="require('@/assets/images/icons/bige-icon-newsletter.svg')"
        width="100%"
        height="128"
        :contain="true"
      />

      <div class="ma-5" style="margin-top:0 !important; margin-bottom:0 !important;">
        <h2
          class="styloText"
          style="text-align:center; margin:0; padding:0;"
        >Rendez-vous enregistré!</h2>
        <p>
          Ton rendez-vou à bien été pris en compte et je te recontacterais dans les plus brefs délais.
          <br />
          Un email vient de t'être envoyé à l'adresse {{contact.email}}, clique sur le lien contenu dans cet email pour finaliser le rendez-vous.
        </p>
      </div>
    </v-container>
  </div>
</template>
<script>
import moment from "moment";
import firebase from "firebase/app";
import "firebase/auth";
window.Date.prototype.addHours = function (h) {
  this.setTime(this.getTime() + h * 60 * 60 * 1000);
  return this;
};

export default {
  name: "Recap",
  props: {
    label: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    recap: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      budget: 0,
      details: null,
      date: null,
      contact: null,
      today: new Date().toISOString().substr(0, 10),
      locale: "fr-FR",
      events: [],
      dateFR: "",
      cgu: false,
      tags: [],
      emailSent: false,
    };
  },
  watch: {
    recap() {
      this.updateAll();
      return this.recap;
    },
  },
  mounted: function () {
    this.updateAll();
  },
  methods: {
    updateAll: function () {
      try {
        this.tags = [];
        if (
          this.recap.filter((entry) => entry.component == "radio").length > 0
        ) {
          for (let entry of this.recap.filter(
            (entry) => entry.component == "radio"
          )) {
            this.tags.push(entry.value);
          }
        }
        if (
          this.recap.filter((entry) => entry.component == "check").length > 0
        ) {
          for (let entry of this.recap.filter(
            (entry) => entry.component == "check"
          )) {
            this.tags.push(entry.value);
          }
        }

        this.recap.filter((entry) => entry.component == "contact").length > 0
          ? (this.contact = this.recap.filter(
              (entry) => entry.component == "contact"
            )[0].value)
          : (this.contact = {});
        this.recap.filter((entry) => entry.component == "details").length > 0
          ? (this.details = this.recap.filter(
              (entry) => entry.component == "details"
            )[0].value)
          : (this.details = {});
        this.recap.filter((entry) => entry.component == "slide").length > 0
          ? (this.budget = this.recap.filter(
              (entry) => entry.component == "slide"
            )[0].value)
          : (this.budget = 0);
        this.recap.filter((entry) => entry.component == "date").length > 0
          ? (this.date = this.recap.filter(
              (entry) => entry.component == "date"
            )[0].value)
          : (this.date = "undefined");
        this.recap.filter((entry) => entry.component == "place").length > 0
          ? (this.place = this.recap.filter(
              (entry) => entry.component == "place"
            )[0].value)
          : (this.place = null);
        // this.events = [];
        //if (this.date.value) {
        if (this.date) this.dateFR = moment(this.date).lang("fr").format("ll");
        if (this.date && this.details) {
          this.events = [];
          this.today = this.date;
          if (this.details) {
            this.events.push({
              name: this.details.label,
              start: this.date,
              end: new Date(this.date)
                .addHours(4)
                .toISOString()
                .replace(/T/, " ") // replace T with a space
                .replace(/\..+/, "")
                .substr(0, 16),
            });
          }

          /* this.$refs.calendar.scrollToTime(
            this.date.substr(this.date.length - 4, this.date.length)
          ); */
        }
      } catch (err) {
        console.log("get an error", err);
      }
      //}
    },
    submit: function () {
      // TODO emit response to the parent component
      this.$emit("clicked", this.selected);
    },
    saveTheDate: async function () {
      // eslint-disable-next-line no-unused-vars
      let contact = await this.$store.state.API.post(`/contact`, {
        name: this.contact.firstname,
        email: this.contact.email,
        label: this.details.label,
        description: this.details.description,
        date: this.date,
        place: this.place,
        tags: this.tags,
        customer_id: this.$store.state.currentUser
          ? this.$store.state.currentUser.uid
          : "undefined",
      });
      var _self = this;
      if (!this.$store.state.currentUser) {
        var actionCodeSettings = {
          // URL you want to redirect back to. The domain (www.example.com) for this
          // URL must be whitelisted in the Firebase Console.
          url: window.location.origin,
          // This must be true.
          handleCodeInApp: true,
        };

        firebase
          .auth()
          .sendSignInLinkToEmail(_self.contact.email.trim(), actionCodeSettings)
          .then(function () {
            // The link was successfully sent. Inform the user.
            // Save the email locally so you don't need to ask the user for it again
            // if they open the link on the same device.
            window.localStorage.setItem(
              "emailForSignIn",
              _self.contact.email.trim()
            );
            _self.emailSent = true;
          })
          .catch(function (error) {
            console.log("error signinemaillink  => ", error);
            // Some error occurred, you can inspect the code: error.code
          });
      } else {
        _self.emailSent = true;
      }
    },
  },
};
</script>

<style scoped>
.my-event {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 2px;
  background-color: #1867c0;
  color: #ffffff;
  border: 1px solid #1867c0;
  font-size: 12px;
  padding: 3px;
  cursor: pointer;
  margin-bottom: 1px;
  left: 4px;
  margin-right: 8px;
  position: relative;
}

.my-event.with-time {
  position: absolute;
  right: 4px;
  margin-right: 0px;
}
</style>