<template >
  <div class="step">
    <v-container pa="0" ma="0">
      <h1 class="styloText">
        <span v-html="label"></span>
      </h1>
      <p>
        <span v-html="description"></span>
      </p>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "Submit",
  props: {
    label: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    button: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  mounted: function () {},
  methods: {
    submit: function () {
      // TODO emit response to the parent component
      this.$emit("clicked", this.selected);
    },
  },
};
</script>