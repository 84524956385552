<template >
  <div class="step">
    <v-container pa="0" ma="0" style="max-width:550px">
      <h1 class="styloText">
        <span v-html="label"></span>
      </h1>
      <p>
        <span v-html="description"></span>
      </p>
      <v-form v-model="valid">
        <v-text-field
          v-model="firstname"
          :rules="nameRules"
          :counter="25"
          label="Ton Prénom"
          required
          v-on:change="updateValue"
        ></v-text-field>
        <v-text-field
          v-model="email"
          :rules="emailRules"
          label="Ton E-mail"
          required
          v-on:change="updateValue"
        ></v-text-field>
        <v-btn color="primary" x-large v-on:click="submit" :disabled="!valid">{{button}}</v-btn>
      </v-form>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "Contact",
  props: {
    label: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    button: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      valid: false,
      firstname: "",
      nameRules: [
        (v) => !!v || "Entre ton Prénom et Nom",
        (v) => v.length <= 25 || "Name must be less than 10 characters",
      ],
      email: "",
      emailRules: [
        (v) => !!v || "Ton E-mail semble éroné",
        (v) =>
          /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/.test(v) ||
          "E-mail must be valid",
      ],
    };
  },
  mounted: function () {},
  methods: {
    updateValue: function () {
      this.value = { email: this.email, firstname: this.firstname };
    },
    submit: function () {
      // TODO emit response to the parent component
      this.$emit("clicked", this.selected);
    },
  },
};
</script>