<template >
  <div class="step">
    <v-container pa="0" ma="0">
      <h1 class="styloText">
        <span v-html="label"></span>
      </h1>
      <p>
        <span v-html="description"></span>
      </p>
      <!-- <v-radio-group v-on:change="submit();" v-model="value"> -->
      <form>
        <v-row class="radioList mb-6" no-gutters align="center" justify="center">
          <v-col xs="6" cols="5" v-for="(choice, index) in choices" :key="index+1" class="colInput">
            <input
              type="radio"
              :id="index+1"
              :value="index+1"
              v-model="value"
              v-on:change="submit();"
              :checked="value === index+1"
            />
            <label :for="index+1">
              <img :src="require(`@/assets/images/contactFrom/SVG/${choice.icon}.svg`)" width="128" />
              {{choice.label}}
            </label>
            <!-- <div v-html="require(`@/assets/images/contactFrom/SVG/${choice.icon}.svg`)"></div> -->
            <!-- <v-radio :label="choice.label" :value="index+1"></v-radio> -->
          </v-col>
        </v-row>
      </form>
      <!-- </v-radio-group> -->
    </v-container>
  </div>
</template>
<script>
export default {
  components: {},
  name: "radioComponent",
  props: {
    label: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    choices: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      value: 0,
    };
  },
  mounted: function () {
    // this.value = 0;
  },
  methods: {},
};
</script>
<style scoped lang="scss">
label {
  display: inline-grid;
}
label img {
  width: 100%;
  height: 128px;
  transform: scale(1);
  cursor: pointer;
  transition: transform 0.2s ease-out;
}
input[type="radio"]:hover + label img {
  transform: scale(1.1);
}
input[type="radio"]:checked + label img {
  filter: invert(1) grayscale(1) hue-rotate(0deg) saturate(100) brightness(100);
  transform: scale(0.8);
}
input[type="radio"]:checked + label {
  color: #5761ff;
}
input[type="radio"] + label:before {
  z-index: 0;
  position: absolute;
  width: 128px;
  height: 128px;
  background-color: #5761ff;
  border-radius: 100%;
  transform: scale(0);
  transition: transform 0.2s linear;
  left: 0;
  right: 0;
  margin: auto;
  content: "";
}
input[type="radio"]:checked + label:before {
  transform: scale(1);
}

.colInput {
  position: relative;
  max-width: 200px;
  width: 50%;

  min-width: unset !important;
}
input[type="radio"] {
  cursor: pointer;
  opacity: 0;
  position: absolute;
  width: 128px;
  height: 128px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 9;
}

@media only screen and (max-width: 450px) {
  .radioList {
    display: inherit;
  }
  label {
    display: flex;
    line-height: 38px;
    margin: 10px;
    width: 100%;
    max-width: 100%;
  }
  label img {
    width: 38px;
    height: 38px;
    margin-right: 15px;
  }
  .colInput {
    max-width: 100%;
    width: 100%;
  }
  input[type="radio"] + label:before {
    width: 38px;
    height: 38px;
    left: 10px;
    top: 0px;
    right: unset;
    margin: 0;
    content: "";
  }
  input[type="radio"] {
    width: 38px;
    height: 38px;
    left: 0;
    right: unset;
    margin: 0;
  }
  .formcol {
    display: block;
  }
  h1 {
    font-size: 24px !important;
  }
  p {
    font-size: 12px;
  }
}
</style>